import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"

const clinicaltrials = ({ data: {allStrapiClinicalTrials}}) => (
    <Layout>
        <main>
            <div class="content">
                <h1>Clinical Trials</h1>
                <p>Welcome to my Gatsby site.</p>
                <div class="tabs">
                    { allStrapiClinicalTrials.nodes.map(contentblock => (
                        <div class="tab">
                            <input type="checkbox" id={"#chck" + contentblock.id}/> 
                            <label class="tab-label" for={"#chck" + contentblock.id}>{contentblock.Hospital}</label>
                            <div class="tab-content">
                               {contentblock.Address_Details}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </main>
    </Layout>
)

export const queryclinicaltrials = graphql`
    query MyQuery {
        allStrapiClinicalTrials {
        nodes {
            Hospital
            Address_Details
            id
        }
        }
    }  
`

export default clinicaltrials